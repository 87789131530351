<template>
  <div class="container oci-check-in-out-body">
    <div
      v-if="isLoadingAssigments"
      class="oci-check-in-salutation"
    >

    </div>  
    <div v-else>
      <div class="oci-check-in-out-heading-date">
        <div class="oci-map-header">
          <div class="float-left">
            <feather-icon
              id="notifications"
              icon="ChevronLeftIcon"
              size="24"
              @click="GoToBackProjectsListPage"
            />
          </div>
        </div>        
        {{ formatDate(site.date) }}
      </div>

      <div class="oci-completed-salutation">
        <h2 class="mb-1">
          Completed
        </h2>
        <h5>{{ subHeading }}</h5>
      </div>
      <Completed />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BImg } from 'bootstrap-vue'
import Completed from '@/views/components/Completed.vue'
import store from '@/store'

export default {
  components: {
    BImg,
    Completed,
  },
  computed: {
    ...mapGetters({ isLoadingAssigments: 'app/getIsLoadingAssigments' }),
    ...mapGetters({ totalAssignments: 'app/getTotalAssignments' }),
    ...mapGetters({ checkedInStatus: 'app/getCheckedInStatus' }),
    ...mapGetters({ userName: 'app/getUserName' }),
    ...mapGetters({ currentDay: 'app/getCurrentDateHeading' }),
    ...mapGetters({ assignmentID: 'app/getUserAssignmentsID' }),
    ...mapGetters({ assignmentsIDList: 'app/getUserAssignmentsIDList' }),
    ...mapGetters({ signintime: 'app/getSignInTime' }),
    ...mapGetters({ userAssignmentSet: 'app/getUserAssignmentSet' }),
    ...mapGetters({ oneAlreadyCheckedIn: 'app/getOneAlreadyCheckedIn' }),
    ...mapGetters({ sitesList: 'app/getSiteDetailsList' }),
    ...mapGetters({ previousAssignmentsID: 'app/getPreviousAssignmentsID' }),    
    ...mapGetters({ site: 'app/getCurrentSiteDetails' }),
        
    subHeading() {
      return 'You have completed this project:'
    },
  },
  data() {
    return {
      jsonData: [],
      noJobsImg: require('@/assets/images/pages/rafiki.png'),
    }
  },
  created() {
    this.$store.dispatch('app/getCurrentJobForCurrentDay', this.assignmentID)
    return
  },
  methods: {
    formatDate(data) {
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];      
      return String(data.day) +' '+ monthNames[data.month-1]+' '+ String(data.year)      
    },   
    GoToBackProjectsListPage() {
      // store.commit('app/setUserAssignmentSet', false)
      store.commit('app/changeUserAssignmentsID', this.previousAssignmentsID)
      this.$router.go(-1)
    },     
  },
}
</script>
